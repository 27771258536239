<template>
	<div class="h-full" v-if="!config || !config.isDetailDisplay">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="initialize" :queryParam="queryParam">
			<el-tabs slot="reserve" class="h-table-switch" v-model="queryParam.ViewStatus" @tab-click="handleClick"
				v-show="!config.isDetailDisplay">
				<el-tab-pane label="当前订单" name="0"></el-tab-pane>
				<!-- <el-tab-pane label="归档订单" name="1"></el-tab-pane> -->
			</el-tabs>
			<div slot="topButtonGroup" class="h-handle-button"
				v-show="(!config.isDetailDisplay && queryParam.ViewStatus * 1 == 0)">
				<div class="h-b">
					<el-button type="primary" size="small" @click="add" v-right-code="'Order:Getemptyorder'">手动开单</el-button>
				</div>
				<!-- <div class="h-b">
					<el-button type="primary" size="small" @click="del" v-right-code="'Order:Getemptyorder'">删除</el-button>
				</div> -->
				<div class="h-b">
					<el-button type="primary" size="small" @click="importOrder" v-right-code="'Order:Import'">导入</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="exportExcel" v-right-code="'Order:Exportfileorder'">导出</el-button>
				</div>
				<div class="h-b">
					<el-dropdown @command="doexcute">
						<el-button type="primary">
							执行<i class="el-icon-caret-bottom el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown"> <!--v-right-code-->
							<el-dropdown-item command="submit" v-right-code="'Order:Approvalorder'">审核
							</el-dropdown-item>
							<el-dropdown-item command="splitorder" v-right-code="'Order:Disassemblyorder'">拆解
							</el-dropdown-item>
							<el-dropdown-item command="intercept" v-right-code="'Order:Interceptorder'">拦截
							</el-dropdown-item>
							<el-dropdown-item command="revertPull" v-right-code="'Order:Interceptorder'">取消拦截
							</el-dropdown-item>
							<el-dropdown-item command="cancel" v-right-code="'Order:Cancel'">取消
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div slot="buttonGroup" class="h-handle-button" v-show="(!config.isDetailDisplay && queryParam.ViewStatus * 1 == 1)">
				<div class="h-b">
					<el-button type="primary" size="small" @click="exportExcel">导出</el-button>
				</div>
			</div>
			<p slot="elList">
				<el-table :data="dataSource.Result" border @selection-change="handleSelectionChange"
					@current-change="handleCurrentChange" highlight-current-row>
					<el-table-column type="selection" width="55" v-if="(!config.isDetailDisplay)"></el-table-column>
					<el-table-column v-for="(col, index)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
						:fixed="index == 0 && (!config || !config.isDetailDisplay)"
						:min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0" v-if="col.Visible">
						<template slot-scope="scope">
							<span v-bind:style="{ color: scope.row.ExceptionCount > 0 ? 'red' : '#1874CD' }"
								v-if="col.FieldName === 'OrderNo'" type="text" @click="rowClick(scope)"
								style="text-decoration:underline;cursor:pointer">{{ scope.row[col.FieldName] }}</span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
		<DialogEx :options="exportConfig" title="导出订单" @onOk="onExportOk">
			<ExportOrder ref="exportOrder" :options="exportConfig" :dataSource="exportDataSource"></ExportOrder>
		</DialogEx>
		<DialogEx :options="interceptConfig" title="拦截订单" @onOk="onInterceptOk">
			<el-input type="textarea" v-model.trim="interceptConfig.interceptRemarks" :maxlength="200">
			</el-input>
		</DialogEx>

		<DialogImport :options="importTemplateConfig" @onOk="onImportTemplateOk" >
			<ImportTemplateExcel ref="importTemplateExcel" :options="importTemplateConfig" :orderType="orderType"
				:dataSource="importDataSource"></ImportTemplateExcel>
		</DialogImport>
		<DialogEx :options="disassemblyConfig" width="200px" title="拆解" @onOk="onDisassemblyConfigOK"
			@onClose="onDisassemblyConfigCancel()">
			<el-form>
				<el-form-item>
					<el-col :span="24">
						<center>请选择需要拆解的类型：</center>
					</el-col>

				</el-form-item>

				<el-form-item>

					<el-col :span="24">
						<el-form-item>
							<center><el-button v-model="Outboundorder" :type="Outboundordertype" @click="Outboundorderclick"
									style="width:200px;">出库</el-button></center>
						</el-form-item>
					</el-col>

				</el-form-item>
				<el-form-item>

					<el-col :span="24">
						<el-form-item>
							<center><el-button v-model="Warehousingentry" :type="Warehousingentrytype"
									@click="Warehousingentryclick" style="width:200px;">入库</el-button></center>
						</el-form-item>
					</el-col>

				</el-form-item>
				<el-form-item>

					<el-col :span="24">
						<el-form-item>
							<center> <el-button v-model="Transportation" :type="Transportationtype"
									@click="Transportationclick" style="width:200px;">运输</el-button></center>
						</el-form-item>
					</el-col>

				</el-form-item>
				<el-form-item>
					<el-col :span="24">
						<center>拆解成功后将形成对应的内部物流单据</center>
					</el-col>
				</el-form-item>
			</el-form>
			<div class="dialog-footer form-title">
				<el-button type="primary" size="small" @click="onDisassemblyConfigOK">确&nbsp;&nbsp;定</el-button>
				<el-button type="default" size="small" @click="onDisassemblyConfigCancel">取&nbsp;&nbsp;消</el-button>
			</div>
		</DialogEx>

	</div>
</template>
<script>
import Cookie from '@/scripts/cookie.js'
import Event from '@/scripts/event.js'
import DialogImport from '../../../../common/dialogimport'
export default {
	mounted() {
		this.Utils.lazy(() => {
			var _this = this;
			this.Event.$on("onRefreshList", () => this.initialize());
		});
		this.initialize(); //加载表格数据
		//批属性管理数据
		//this.getLotManage();
	},
	data() {
		return {
			proDateDisplay: false,
			proBatchNoDisplay: false,
			lotManages: [], //批属性管理数据
			multipleSelection: [],
			disassemblyConfig:
			{
				visible: false,
				width: '200px',
				modal: true,
				showFooter: false,
				closeByModal: true,
			},
			Transportation: false,
			Warehousingentry: false,
			Outboundorder: false,
			Transportationtype: "info",
			Warehousingentrytype: "info",
			Outboundordertype: "info",
			queryParam: {
				PageIndex: 1,
				PageSize: 10,
				Params: {
					OrderStatusCode: -1
				},
				ViewStatus: 0,
				TabStatus: 0,
				TabShow: true,
				TabItems: [],
			},
			dataSource: {
				ColDefs: {},
				Result: [],
				TotalCount: 0
			},
			exceptionDataSource: {
				CustomerName: "",
				CustomerCode: "",
				MemberName: "",
				MemberCode: "",
				ExceptionType: "",
				ExceptionCategory: "",
				OrderNo: "",
				ExceptionTypeID: "",
				ExceptionDesc: "",
			},
			exportDataSource: {
				busType: "",
				queryParam: "",
				url: ""
			},
			senderDataSource: {
				OrderNo: "",
				CustomerCode: "",
				Name: "",
				Mobile: "",
			},
			interceptConfig: {
				visible: false,
				size: 'tiny',
				interceptRemarks: null,
			},
			exceptionConfig: {
				visible: false,
				size: 'small',
			},
			exportConfig: {
				visible: false,
				size: 'small',
			},
			senderInfoConfig: {
				visible: false,
				size: 'small',
			},
			SearchCountData: {
				TotalCount: '',
				WaitDistCount: '',
				WaitAuditCount: '',
				StayDownCount: '',
				AlreadyDownCount: '',
				FinishReceiveCount: '',
				CloseCount: ''
			},
			orderType: 0,
			importTemplateConfig: {

				visible: false,
				size: 'large',
				title: '',
			},
			importDataSource: {
				CustomerCode: "",
				CustomerList: [],
			}
		}
	},
	props: {
		config: {
			isDetailDisplay: false,
			isAdd: false
		},
	},
	methods: {
		handleClick(tab, event) {
			this.queryParam.ViewStatus = tab.name;
			this.config.OrderDisplayTag = tab.name;
			this.initialize();
		},
		showlist: function (index) {
			this.queryParam.Params.OrderStatusCode = index;
			this.queryParam.PageIndex = 1; //切换订单状态时 初始化值
			this.initialize();
		},
		onChangeEditDataSource: function (data) {
			this.config.isDetailDisplay = true;
			this.$emit("onChangeEditDataSource", data);
		},
		handleCurrentChange: function (val) {
			this.$emit("onSelectedRow", val);
		},
		onDataSourceChange(ds) {
			var _this = this;
			_this.dataSource = {
				ColDefs: {
					BodyFieldParams: []
				},
				Result: [],
				TotalCount: 0
			};
			_this.$nextTick(function () {
				_this.dataSource = ds;

			});
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		rowClick: function (scope) {
			this.onChangeEditDataSource(scope.row.Id);
		},
		buildAddress: function (dataSource) {
			if (!dataSource) return;
			dataSource.ReceiverSource = {
				NationName: '',
				NationCode: '',
				ProvinceName: dataSource.ProvinceName,
				ProvinceID: dataSource.ProvinceID,
				CityName: dataSource.CityName,
				CityID: dataSource.CityID,
				CountyName: dataSource.CountyName,
				CountyID: dataSource.CountyID,
				Town: dataSource.Town,
			};
			dataSource.SenderSource = {
				NationName: '',
				NationCode: '',
				ProvinceName: dataSource.SenderProvince,
				ProvinceID: dataSource.SenderProvinceCode,
				CityName: dataSource.SenderCity,
				CityID: dataSource.SenderCityCode,
				CountyName: dataSource.SenderCounty,
				CountyID: dataSource.SenderCountyCode,
				Town: dataSource.SenderTown,
			};
		},
		add: function () {
			this.onChangeEditDataSource(null);
		},
		del: function () {
			if (this.multipleSelection.length <= 0) {
				this.Utils.messageBox("请选择订单");
				return;
			}
			for (var i = 0; i < this.multipleSelection.length; i++) {
				if (this.multipleSelection[i].OrderStatus != 120) {
					this.Utils.messageBox(`订单${this.multipleSelection[i].OrderNo}操作失败，只有取消状态订单才能删除。`);
					return;
				}
			}
			var _this = this;
			this.Utils.confirm({
				content: "确认删除选中订单吗?"
			}, () => {
				var orderNos = _this.Utils.selectionsToArr(_this.multipleSelection, "OrderNo");
				this.$ajax.send("omsapi/order/delete", "post", orderNos, (result) => {
					_this.initialize();
					_this.Utils.messageBox(result.OperationDesc || "操作成功", "success");
				});
			});
		},
		initialize: function () {
			var _this = this;
			// var param = JSON.parse(JSON.stringify(_this.queryParam));
			var param = _this.queryParam;
			if (this.selectCondition) {
				this.Utils.extend(param.Params, this.selectCondition);
			}
			if (_this.queryParam.ViewStatus * 1 == 0) {
				this.$ajax.query("omsapi/order/getPageList", "post", param, (result) => {
					_this.dataSource = result;
					_this.queryParam.TabItems = result.TabItems;
				});
				// this.$ajax.query("omsapi/order/getpurchaseordercount", "post", param, (data) => {
				//     _this.SearchCountData = data.Result;
				// });
			} else {
				this.$ajax.query("omsapi/order/getPageHisList", "post", param, (result) => {
					_this.dataSource = result;
				});
			}
		},
		submit: function (command) {
			var _this = this;
			var orderNos = _this.Utils.selectionsToArr(_this.multipleSelection, "OrderNo");
			_this.$ajax.send("omsapi/order/approvalOrder", "post", orderNos, (result) => {
				_this.Utils.messageBox(result.OperationDesc || "操作成功", "success");
				_this.initialize();
			}, null, true);
		},
		splitorder: function (command) {
			var _this = this;
			if (_this.multipleSelection.length > 1) {
				_this.Utils.messageBox("只能选择一个订单进行拆解", "error");
				return;
			}
			var logisticsorder = _this.multipleSelection[0];

			if (logisticsorder.DisassemblyProcessStatus == 200) {
				_this.Utils.messageBox("已拆解订单无法再进行拆解", "error");
				return;
			}

			_this.disassemblyConfig.visible = true;
		},
		issue: function (command) {
			var _this = this;
			var orderNos = _this.Utils.selectionsToArr(_this.multipleSelection, "OrderNo");
			_this.$ajax.send("omsapi/order/issue", "post", orderNos, (result) => {
				_this.Utils.messageBox(result.OperationDesc || "操作成功", "success");
				_this.initialize();
			}, null, true);
		},
		intercept: function (command) {
			var orderIds = this.Utils.selectionsToArr(this.multipleSelection, "Id");
			if (!orderIds || orderIds.length <= 0) {
				this.Utils.messageBox("请选择订单", "error");
				return;
			}
			this.interceptConfig.visible = true;
		},
		onInterceptOk: function (options) {
			var _this = this;
			var orderIds = _this.Utils.selectionsToArr(_this.multipleSelection, "Id");
			_this.$ajax.send("omsapi/order/interceptOrder", "post", {
				OrderIds: orderIds,
				Remark: _this.interceptConfig.interceptRemarks
			}, (result) => {
				_this.Utils.messageBox(result.OperationDesc || "操作成功", "success");
				_this.interceptConfig.interceptRemarks = null;
				_this.interceptConfig.visible = false;
				_this.initialize();
			}, null, true);
		},
		revert: function (command) {
			var _this = this;
			var orderNos = _this.Utils.selectionsToArr(_this.multipleSelection, "OrderNo");
			_this.$ajax.send("omsapi/order/revert", "post", orderNos, (result) => {
				_this.Utils.messageBox(result.OperationDesc || "操作成功", "success");
				_this.initialize();
			}, null, true);
		},
		cancel: function (command) {
			var _this = this;
			var orderNos = _this.Utils.selectionsToArr(_this.multipleSelection, "OrderNo");
			_this.$ajax.send("omsapi/order/cancel", "post", orderNos, (result) => {
				_this.Utils.messageBox(result.OperationDesc || "操作成功", "success");
				_this.initialize();
			}, null, true);
		},
		exportExcel: function () {
			var _this = this;
			//过滤不用的查询条件
			var queryParam = _this.queryParam; // _this.Utils.exportQueryParam(_this.queryParam);
			/* 	queryParam.ViewStatus = _this.queryParam.ViewStatus;
				queryParam.Params.OrderStatusCode = {
					FieldName: "OrderStatusCode",
					FieldValue: _this.queryParam.Params.OrderStatusCode
				}; */
			queryParam = JSON.parse(JSON.stringify(queryParam));
			delete queryParam.TabItems;
			delete queryParam.Params;


			var _this = this;
			this.$ajax.send("omsapi/customerinfo/getcurrentcustomers", "get", {}, (data) => {
				/* 	_this.importTemplateConfig.visible = true;
					_this.importTemplateConfig.title = "上传订单导入文件";
					_this.orderType = 1;
					_this.importDataSource = {};
					_this.importDataSource.CustomerList=data.Result; */

				_this.exportConfig.visible = true;
				if (_this.queryParam.ViewStatus * 1 == 0) {
					_this.exportDataSource = {
						busType: "1",
						queryParam: queryParam,
						url: "omsapi/order/exportfileorder",
						CustomerCode: "",
						CustomerList: data.Result
					};
				} else {
					_this.exportDataSource = {
						busType: "1",
						queryParam: str,
						url: "omsapi/order/exportfileorder",
						CustomerCode: "",
						CustomerList: data.Result
					};
				}

			});

		},
		onExportOk: function (options) {
			var _this = this;
			_this.$refs.exportOrder.submit(options);
		},
		print: function () {

		},
		onExceptionOk: function (options) {
			var _this = this;
			_this.$refs.exception.submit(options);
		},
		onSenderInfoOk: function (options) {
			var _this = this;
			_this.$refs.senderInfo.submit(options);
		},
		batchUpdate: function (command) {
			if (this.multipleSelection.length <= 0) {
				this.Utils.messageBox("请选择订单", "error");
				return;
			}
			switch (command) {
				case "updateSenderInfo": //修改发货人
					this.updateSenderInfo();
					break;
				default:
					break;
			}
		},
		updateSenderInfo: function () {
			var _this = this;
			if (_this.multipleSelection.length > 1) {
				this.Utils.messageBox("只能选择单个订单信息！", "error");
				return;
			}
			if (_this.multipleSelection[0].OrderStatus != 100) {
				this.Utils.messageBox("只能选择草稿状态订单！", "error");
				return;
			}
			_this.senderDataSource = {
				OrderNo: _this.multipleSelection[0].OrderNo,
				CustomerCode: _this.multipleSelection[0].CustomerCode,
				IsSender: true
			};
			_this.senderInfoConfig.visible = true;
		},
		doexcute: function (command) {
			if (this.multipleSelection.length <= 0) {
				this.Utils.messageBox("请选择订单", "error");
				return;
			}
			if (!this.Utils.orderCommand(command, this.multipleSelection)) {
				return;
			}
			switch (command) {
				case "submit": //审核订单
					this.submit();
					this.initialize();
					break;
				case "splitorder": //订单
					this.splitorder();
					break;
				case "issue": //下发订单
					this.issue();
					this.initialize();
					break;
				case "intercept": //拦截
					this.intercept();
					break;
				case "revert": //取消审核订单
					this.revert();
					this.initialize();
					break;
				case "cancel": //取消订单
					this.cancel();
					break;
				default:
					break;
			}
		},
		//批属性管理数据
		getLotManage() {
			this.$ajax.send("omsapi/lotmanage/search", "get", {
				excludeOldField: false
			}, (data) => {
				for (let index = 0; index < data.Result.length; index++) {
					var lot = data.Result[index];
					if (lot.DefaultLotProp == 'ProductDate' && lot.IsDisplay) {
						this.proDateDisplay = true;
					}
					if (lot.DefaultLotProp == 'ProductBatchNo' && lot.IsDisplay) {
						this.proBatchNoDisplay = true;
					}
					if (lot.Sort > 2) { //排除默认的四个属性和批属性1、2
						this.lotManages.push(lot);
					}
				}
			});
		},
		importOrder() {
			var _this = this;
			this.$ajax.send("omsapi/customerinfo/getcurrentcustomers", "get", {}, (data) => {
				_this.importTemplateConfig.visible = true;
				_this.importTemplateConfig.title = "上传订单导入文件";
				_this.orderType = 1;
				_this.importDataSource = {};
				_this.importDataSource.CustomerList = data.Result;
			});



		},
		onImportTemplateOk: function (options) {
			var _this = this;
			_this.$refs.importTemplateExcel.saveFlie(options, false);
			this.onPageChange(_this.queryParam);
		},
		onContinue: function (options) {
			var _this = this;
			_this.$refs.importTemplateExcel.saveFlie(options, true);
			this.onPageChange(_this.queryParam);
		},
		Transportationclick: function () {
			this.Transportation = !this.Transportation;

			if (!this.Transportation) {
				this.Transportationtype = "info";
			} else {
				this.Transportationtype = "primary";
			}
		},
		Warehousingentryclick: function () {
			this.Warehousingentry = !this.Warehousingentry;

			if (!this.Warehousingentry) {
				this.Warehousingentrytype = "info";
			} else {
				this.Warehousingentrytype = "primary";
			}
		},
		Outboundorderclick: function () {
			this.Outboundorder = !this.Outboundorder;

			if (!this.Outboundorder) {
				this.Outboundordertype = "info";
			} else {
				this.Outboundordertype = "primary";
			}
		},
		onDisassemblyConfigCancel: function () {
			this.disassemblyConfig.visible = false;
			this.Outboundorder = false;
			this.Warehousingentry = false;
			this.Transportation = false;
			this.Transportationtype = "info";
			this.Warehousingentrytype = "info";
			this.Outboundordertype = "info";
		},
		onDisassemblyConfigOK: function () {
			var _this = this;
			if (!this.Transportation && !this.Warehousingentry && !this.Outboundorder) {
				_this.Utils.messageBox("请选择至少一种拆解类型", "error");
				return;
			}
			var orderIds = _this.Utils.selectionsToArr(_this.multipleSelection, "Id");

			_this.$ajax.send("omsapi/order/disassemblyorder", "post", { Id: orderIds[0], Outboundorder: _this.Outboundorder, Warehousingentry: _this.Warehousingentry, Transportation: _this.Transportation }, (data) => {
				if (data.IsSuccess) {
					_this.Utils.messageBox("拆解成功", "success");
					_this.initialize();
					_this.Outboundorder = false;
					_this.Warehousingentry = false;
					_this.Transportation = false;
					_this.Transportationtype = "info";
					_this.Warehousingentrytype = "info";
					_this.Outboundordertype = "info";
					_this.disassemblyConfig.visible = false;
				} else {
					_this.Utils.messageBox(data.OperationDesc, "error");
				}
			});
		},
	},
	components: {
		"ExportOrder": resolve => { require(['../../components/exportexceltemplate.vue'], resolve) },
		//"Sender": resolve => { require(['@/components/business/order/components/editreceiversenderInfo.vue'], resolve) },
		"ImportTemplateExcel": resolve => {
			require(['../../components/importexcelTemplate.vue'], resolve)
		},
		DialogImport
	}
}
</script>