<template>
    <el-dialog :visible.sync="options.visible" :before-close="onClose" :size="Size" :show-close="showClose" :modal="options.modal" :close-on-click-modal="options.closeOnClickModal">
        <div slot="title" class="dialog-title">{{Title}}</div>
        <slot v-if="options.visible"></slot>
        <span slot="footer" class="dialog-footer" v-if="(options.showFooter == void 0 ? true : options.showFooter)">
            <slot name="ButtonGroupLeft"></slot>
            <el-button @click="onCancel">取 消</el-button>
            <slot name="ButtonGroupCenter"></slot>
            <el-button type="primary" @click="onOk">{{okText}}</el-button>
            <!-- <slot name="ButtonGroupRight"></slot>
            <el-button type="primary" @click="onContinue">{{continueText}}</el-button> -->
        </span>
    </el-dialog>
</template>
<script>
    export default {
        name:"DialogImport",
        data(){
            return {
            }
        },
        props:{
            options:{
                title:"",
                visible:false,
                size: '',//	Dialog 的大小	tiny/small/large/full
                showFooter: true,
                modal: true,//是否需要遮罩层
                closeOnClickModal: true   //是否可以通过点击 modal 关闭 Dialog
            },
            title: '',
            okText: {
                type: String,
                default:'确 定'
            },
            continueText: {
                type: String,
                default:'执行并导入'
            },
            showClose:{
                type:Boolean,
                default:true
            },
            confirmMsg: null
        },
        computed:{
            Size:function(){
                return this.options.size || "tiny";
            },
            Title:function(){
                return this.options.title || this.title || "dialog";
            }
        },
        methods:{
            onContinue: function () {
                let _this = this;
                if (this.confirmMsg) {
                    this.$confirm(this.confirmMsg, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        doOk();
                    });
                } else {
                    _this.options.visible = true;
                    _this.$emit("onContinue", _this.options);
                    _this.$emit("onOk", _this.options);
                    //_this.$emit("onCompleted");
                }
            },
            onOk: function () {
                let _this = this;
                if (this.confirmMsg) {
                    this.$confirm(this.confirmMsg, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        doOk();
                    });
                } else {
                    doOk();
                }
                function doOk() {
                    _this.options.visible = false;
                    _this.$emit("onOk", _this.options);
                    _this.$emit("onCompleted");
                }
            },
            onCancel:function(){
                this.options.visible = false;
                this.$emit("onCancel", this.options);
                this.$emit("onCompleted");
            },
            onClose:function(){
                this.options.visible = false;
                this.$emit("onClose", this.options);
                this.$emit("onCompleted");
            }
        }
    }
</script>
<style>

</style>